import React from 'react';
import { inject, observer } from 'mobx-react';
import { List, Loader, Icon, Segment, Popup, Header, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash';

import Form from '../../components/ui/Form';
import ListView from '../../components/ListView';
import Switch from '../../components/Switch';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';
import Avatar from '../../components/Avatar';

import EntityRecipients from '../Entity/ui/Recipients';

import { __ } from '../../i18n';

import '../../assets/css/ui/Report.module.scss';

const PAGE_SIZE = 40;

const styles = {
  avatar: {
    position: 'static',
    margin: '0px 8px',
    height: '2.5em'
  },
  cell: {
    minWidth: '114px',
    height: '66px'
  },
  content: {
    padding: 0,
    borderRadius: '0.5rem'
  },
  description: {
    backgroundColor: '#FFFFFF',
    border: 'none',
    fontSize: '16px'
  },
  entityCell: {
    display: 'flex',
    backgroundColor: '#ffffff',
    height: '66px',
    width: '173px'
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    height: '45px',
    borderRadius: 0
  },
  grid: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  gridCol: {
    width: '114px'
  },
  gridColFixed: {
    position: 'sticky',
    zIndex: 9998,
    backgroundColor: '#ffffff',
    width: '200px'
  },
  gridContainer: {
    display: 'grid', /* This is a (hacky) way to make the .grid element size to fit its content */
    overflow: 'auto',
    height: '480px',
    width: '70%',
    backgroundColor: '#ffffff'
  },
  headerCell: {
    minWidth: '114px',
    height: '66px'
  },
  reportName: {
    color: '#000000',
    fontSize: '16px'
    // fontWeight: 'bold'
  },
  reportDescription: {
    color: '#000000',
    fontSize: '16px'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 24px',
    height: '60px',
    borderRadius: 0
  },
  rowDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    position: 'relative',
    paddingRight: '3.5em'
  },
  selectedField: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    height: '45px',
    borderRadius: 0,
    backgroundColor: '#eeeeee'
  },
  text: {
    width: '330px'
  }
};

@inject('store')
@graphql(gql`query ReportResultFormQuery($entityId: ID!, $limit: Int, $offset: Int) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      reports (limit: $limit, offset: $offset) {
        nodes {
          id: dbId
          name
          selected
          description
          fields (limit: 400, orderBy: { column: POSITION }) {
            nodes {
              id: dbId
              name
              type
              options
              initial
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
}`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      entityId: ownProps.entity.id,
      limit: PAGE_SIZE,
      offset: 0
    }
  })
})
@observer
export default class ReportResultForm extends Form {
  state = {
    recipients: [],
    selectedField: 0,
    fetchedAll: false
  }

  defaultValues = {
    name: '',
    id: null,
    description: null,
    fields: [],
    multiple: false,
    report: null,
    entities: []
  }

  rules = {
    fields: [values => this.validateContent(values)]
  }

  componentDidMount() {
    const { values } = this.state;

    if (values.multiple && values.fields && values.fields.length) {
      const recipients = this.props.recipients.map(recipient => recipient.id);

      values.entities = values.entities.filter(result => recipients.indexOf(result.id) > -1);

      const entities = values.entities.map(entity => entity.id);
      const results = values.fields.map(field => field.result);

      this.props.recipients.forEach((recipient) => {
        if (entities.indexOf(recipient.id) === -1) {
          values.entities.push({ ...recipient, fields: results.map(r => (typeof r === 'object' ? r.map(e => cloneDeep(e)) : r)) });
        }
      });

      this.setState({ values });
    }
  }

  beforeSubmit = (values) => {
    values.recipients = this.state.recipients;
    values.selectedAll = this.state.selectedAll !== undefined ? this.state.selectedAll : this.props.selectedAll;
    values.fetchedAll = this.state.fetchedAll || this.props.fetchedAll;

    values.allRecipients = this.state.allRecipients;
    values.groups = this.state.groups;
    values.allGroups = this.state.allGroups;

    //remove unecessary values
    return values;
  }

  validateContent = (fields) => {
    if (this.state.values.multiple) return;

    const err = fields.filter((field) => {
      if (field.type === 'CHECK') {
        return !!field.result.filter(option => option.selected).length;
      }

      return !!field.result;
    }).length === 0;

    if (err) {
      throw new Error(__("You can't add an empty report"));
    }
  }

  onCancel = () => {
    if (this.props.edit || (this.state.values.fields && this.state.values.fields.length)) {
      this.checkBeforeLeaving();
    } else {
      this.props.onCancel(this.state.recipients, this.state.selectedAll !== undefined ? this.state.selectedAll : this.props.selectedAll, this.state.fetchedAll || this.props.fetchedAll, this.state.allRecipients, this.state.groups, this.state.allGroups);
      this.onSelectReport({});
    }
  }

  onSubmit = () => {
    if (this.state.values.fields && this.state.values.fields.length) {
      return this.handleSubmit;
    } else if (this.state.values.multiple &&
      ((this.props.selectedAll && !(this.props.fetchedAll || this.state.fetchedAll)) ||
        (!this.state.recipients.length && !this.props.recipients.length))) {
      return this.openEntityRecipientForm;
    }
    return this.onSubmitParsedValues;
  }

  openEntityRecipientForm = () =>
    this.props.store.appends.push(
      <BetaModal
        id={'recipientAttachmentForm'}
        onClose={() => this.props.store.appends.pop()}
        size="tiny"
        actions
        scrolling
        header={__('Recipients')}
        content={
          <EntityRecipients
            modal
            fetchOnSubmit
            onSubmit={(data) => {
              const groups = data.groups.nodes.filter(group => group.selected);
              const recipients = data.recipients.nodes.filter(recipient => recipient.selected);

              if (groups.length || recipients.length) {
                this.props.store.appends.pop();
                this.handleSelectedRecipients(data);
              } else {
                this.props.store.snackbar = { active: true, message: __('You must select at least one group or recipient'), success: false };
                this.handleSelectedRecipients(data, false);
              }
            }}
            // submitButton={__('Save')}
            submitButton={{ isActionButtom: true, text: __('Save') }}
            cancelButton={{ isActionButtom: true }}
            onCancel={() => this.props.store.appends.pop()}
            organization={this.props.organization}
            entity={this.props.entity}
          />
        }
      />
    );

  handleSelectedRecipients = (data, submitParsedValues = true) => {
    const selectedAll = data.selected;
    const fetchedAll = selectedAll;
    const groups = data.groups.nodes.filter(group => group.selected);
    const allGroups = data.groups.nodes;
    const recipients = data.recipients.nodes.filter(recipient => recipient.selected || !!(groups.filter(g => g.entities.nodes.findIndex(e => e.id === recipient.id) > -1).length));
    const allRecipients = data.recipients.nodes;

    this.setState({ recipients, selectedAll, fetchedAll, allRecipients, groups, allGroups }, () => {
      if (submitParsedValues) {
        this.onSubmitParsedValues();
      }
    });
  }

  onSubmitParsedValues = () => {
    const { values: { report: { fields, id, name, description } } } = this.state;
    const values = { fields: cloneDeep(fields), id, name, description };

    values.fields = values.fields.nodes.map((field) => {
      if (field.type === 'SELECT') {
        field.result = field.options[parseInt(field.initial, 10)];
      } else if (field.type === 'CHECK') {
        const parsed_initial = field.initial && field.initial.length ? field.initial.map(o => parseInt(o, 10)) : null;
        field.result = field.options.map((value, j) => (
          {
            value,
            selected: (parsed_initial) ? parsed_initial.indexOf(j) > -1 : false
          }
        ));
      } else if (field.type === 'TEXT') {
        field.result = field.initial || '';
      }
      return field;
    });

    if (this.state.values.multiple) {
      const recipients = this.props.recipients.length ? this.props.recipients : this.state.recipients;
      const results = values.fields.map(field => field.result);
      values.entities = recipients.map(recipient => ({ ...recipient, fields: cloneDeep(results) }));
    }
    this.setState({ values: { ...this.state.values, ...values } });
  }

  onSelectReport = (values) => {
    this.setState({ values: { ...this.state.values, report: null } });

    this.props.data.updateQuery((previousResult) => {
      const nextResult = cloneDeep(previousResult);

      nextResult.node.reports.nodes = nextResult.node.reports.nodes.map((report) => {
        report.selected = report.id === values.id;

        if (report.selected) this.setState({ values: { ...this.state.values, report } });

        return report;
      });

      return nextResult;
    });
  }

  handleChange = (type, data, i, j, all) => {
    const { values, selectedField } = this.state;

    if (all && type === 'CHECK') {
      const checked = !values.fields[selectedField].result[j].selected;

      values.fields[selectedField].result[j].selected = checked;

      values.entities = values.entities.map((entity) => {
        entity.fields[selectedField][j].selected = checked;

        return entity;
      });
    } else if (all && type === 'SELECT') {
      if (values.fields[selectedField].result === data) data = undefined;

      values.fields[selectedField].result = data;

      values.entities = values.entities.map((entity) => {
        entity.fields[selectedField] = data;

        return entity;
      });
    } else if (type === 'TEXT') {
      values.entities[i].fields[selectedField] = data.value;
    } else if (type === 'CHECK') {
      values.entities[i].fields[selectedField][j].selected = !values.entities[i].fields[selectedField][j].selected;
    } else {
      let selected = data;

      if (values.entities[i].fields[selectedField] === data) selected = undefined;

      values.entities[i].fields[selectedField] = selected;
    }

    this.setState({ values });
  }

  setField(i, value) {
    const values = this.state.values;
    values.fields[i].result = value;
    this.setState({ values });
  }

  renderFieldName = field => <p className="bold wordWrapped" style={{ padding: '16px 8px', margin: '0px', borderBottom: '1px solid #eeeeee' }}>{field.name}</p>

  renderCheck = (field, i) => (
    <Form.Field data-name="checkbox" data-value={i} key={i} style={{ margin: '0px' }}>
      {this.renderFieldName(field)}
      <div style={{ padding: '16px 8px' }}>
        {
          field.result.map((option, j) =>
            <div style={{ marginTop: '10px' }}>
              <ColoredCheckbox
                color={'#084FFF'}
                data-value={j}
                key={j}
                radio
                bold={false}
                radioChecked
                label={option.value}
                checked={option.selected}
                onClick={() => {
                  field.result[j].selected = !field.result[j].selected;
                  this.setField(i, field.result);
                }}
              />
            </div>)
        }
      </div>
    </Form.Field>
  );

  renderSelect = (field, i) => {
    const options = field.options.map((option, k) => ({ text: option, value: option, 'data-value': k }));

    return (
      <div>
        {this.renderFieldName(field)}
        <div style={{ padding: '16px 8px' }}>
          <Form.Dropdown
            data-action="open-select-option"
            data-params={i}
            key={i}
            selection
            search
            noResultsMessage={__('No results were found')}
            options={options}
            value={field.result}
            onChange={(e, data) => this.setField(i, data.value)}
          />
        </div>
      </div>
    );
  };

  renderText = (field, i) => (
    <div>
      {this.renderFieldName(field)}
      <div style={{ padding: '16px 8px' }}>
        <Form.FormTextArea
          data-name="textarea"
          data-value={i}
          key={i}
          rows="5"
          value={field.result}
          onChange={(e, data) => this.setField(i, data.value)}
        />
      </div>
    </div>
  );

  renderFields = () =>
    this.state.values &&
    this.state.values.fields &&
    this.state.values.fields.map((field, i) => {
      if (field.type === 'CHECK') {
        return this.renderCheck(field, i);
      } else if (field.type === 'SELECT') {
        return this.renderSelect(field, i);
      }
      return this.renderText(field, i);
    })

  renderRow = (report, key) =>
    <List.Item
      data-action="select-report"
      data-params={report.id}
      key={key}
      onClick={() => this.onSelectReport(cloneDeep(report))}
      style={styles.row}
    >
      <div style={styles.rowDiv}>
        <div>
          <span style={styles.reportName} className="wordWrapped">{(report.name.length > 100) ? (report.name.substring(0, 100) + '...') : report.name}{report.description && ' — '}</span>
          {report.description && <span style={styles.reportDescription}>{report.description}</span>}
        </div>
        <ColoredCheckbox color={'#084FFF'} radio checked={this.state.values.report && this.state.values.report.id === report.id} style={{ fontSize: '16px', position: 'absolute', right: 0 }} />
      </div>
    </List.Item>

  loadMore = () => {
    const { data } = this.props;

    if (!data.node.reports.pageInfo.hasNextPage) return;

    data.fetchMore({
      variables: {
        offset: data.node.reports.nodes.length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (!fetchMoreResult || fetchMoreResult.node.reports.nodes.length === 0) {
          return previousResult;
        }

        fetchMoreResult.node.reports.nodes =
          [...previousResult.node.reports.nodes, ...fetchMoreResult.node.reports.nodes];

        return fetchMoreResult;
      },
    });
  }

  renderFieldsView = () => {
    const { errors } = this.state;

    return (<div>
      {this.renderFields()}
      {
        errors && errors.fields ?
          <div>
            <Icon data-value="error" name="exclamation circle" size="large" color="red" />
            {errors.fields}
          </div>
          :
          null
      }
    </div>);
  }

  checkAnswered = (type, result) => {
    switch (type) {
      case 'SELECT': return result && result.length > 0;
      case 'TEXT': {
        return result && (typeof result === 'string' ? result.trim().length > 0 : result[0] && result[0].trim().length > 0);
      }
      default: return result && result.filter(option => option.selected).length > 0;
    }
  }

  renderMultiple = () => {
    const { values, selectedField } = this.state;
    const field = values.fields[selectedField];

    if (values.entities.length === 0) {
      return (
        <div style={{ padding: '2em', backgroundColor: '#ffffff' }}>
          <Icon data-value="error" name="exclamation circle" size="large" color="red" />
          {__('Please select the recipients')}
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', maxHeight: '480px' }}>
        <div style={{ width: '30%', borderRight: '1px solid #eeeeee' }}>
          <div style={{ height: '50px' }}>
            <p className="bold" style={{ ...styles.reportDescription, fontSize: '12px', padding: '24px 24px 12px 24px' }}>
              {__('Fields list').toUpperCase()}
            </p>
          </div>
          <List selection style={{ overflowY: 'scroll', height: '430px', margin: 0 }}>
            {values.fields.map((f, i) => <List.Item
              data-action="select-field"
              data-params={f.id}
              key={i}
              onClick={() => this.setState({ selectedField: i })}
              style={selectedField === i ? styles.selectedField : styles.field}
            >
              <div style={styles.rowDiv}>
                <span
                  className="bold"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <Popup
                    trigger={<span>{f.name}</span>}
                    content={<span className="wordWrapped">{f.name}</span>}
                  />
                </span>
                <span style={styles.reportDescription}>{values.entities.filter(entity => this.checkAnswered(f.type, entity.fields[i])).length}/{values.entities.length}</span>
              </div>
            </List.Item>
            )}
          </List>
        </div>
        <div style={styles.gridContainer}>
          <div style={styles.grid}>
            <div style={{ ...styles.gridCol, ...styles.gridColFixed, left: 0 }}>
              <div className="gridItem gridItemHeader" style={{ width: '200px', backgroundColor: '#ffffff' }} />
              {
                values.entities.map(entity => <div className="gridItem left" style={{ width: '200px' }}>
                  <Header as="h5" className="entityItem" title={entity.fullname} data-id={entity.id}>
                    <Avatar avatar size="mini" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} style={styles.avatar} />
                    <Header.Content className={'no-padding'} style={{ borderRadius: '0.5rem' }}>
                      {
                        entity.fullname ?
                          (
                            entity.fullname.length > 15 ?
                              <Popup
                                trigger={<span>{entity.fullname.substring(0, 15).trim()}...</span>}
                                content={entity.fullname}
                              />
                              :
                              <span>{entity.fullname}</span>
                          )
                          :
                          null
                      }
                    </Header.Content>
                  </Header>
                </div>)
              }
            </div>
            {
              field.type === 'TEXT' ?
                <div style={styles.text}>
                  <div className="gridItem gridItemHeader wordWrapped">
                    {
                      field.name.length > 48 ?
                        <Popup
                          trigger={<span>{field.name.substring(0, 48).trim()}...</span>}
                          content={field.name}
                        />
                        :
                        <span>{field.name}</span>
                    }
                  </div>
                  {
                    values.entities.map((entity, j) =>
                      <div className="gridItem">
                        <Form.FormTextArea
                          data-name="textarea"
                          style={{ ...styles.text, height: '47px', resize: 'none' }}
                          value={entity.fields[selectedField]}
                          onChange={(e, data) => this.handleChange(field.type, data, j)}
                        />
                      </div>)
                  }
                </div>
                :
                field.options.map((option, i) => <div style={styles.gridCol}>
                  <div className="gridItem gridItemHeader pointer wordWrapped" onClick={() => this.handleChange(field.type, option, null, i, true)}>
                    {
                      option.length > 24 ?
                        <Popup
                          trigger={<span>{option.substring(0, 24).trim()}...</span>}
                          content={option}
                        />
                        :
                        <span>{option}</span>
                    }
                  </div>
                  {
                    values.entities.map((entity, j) => <div className="gridItem">
                      {
                        field.type === 'SELECT' ?
                          <ColoredCheckbox
                            color={'#084FFF'}
                            radio
                            radioChecked
                            checked={entity.fields[selectedField] === option}
                            onClick={() => this.handleChange('SELECT', option, j)}
                            style={{ fontSize: '16px' }}
                          />
                          :
                          <ColoredCheckbox
                            color={'#084FFF'}
                            checked={entity.fields[selectedField][i].selected}
                            onClick={() => this.handleChange('CHECK', option, j, i)}
                            style={{ fontSize: '16px' }}
                          />
                      }
                    </div>)
                  }
                </div>)
            }
          </div>
        </div>
      </div>
    );
  }

  renderOptions = () => {
    const { values } = this.state;
    const { data } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active centered />;

    const { nodes, pageInfo } = data.node.reports;

    return (<div>
      <p className="bold" style={{ fontSize: '16px', marginBottom: '12px', fontWeight: 'bold' }}>{__('Report Type')}</p>
      <Switch
        label={__('How many options can be selected?')}
        name="type"
        color={'#084FFF'}
        options={[{ value: false, text: __('Same for Everyone') }, { value: true, text: __('Specific to Each Person') }]}
        checked={values.multiple}
        onChange={(e, d) => this.setState({ values: { ...values, multiple: d.value } })}
      />
      <Segment style={styles.description}>
        {
          !values.multiple ?
            __('Fill a generic report to all your recipients. All recipients will receive the same report.')
            :
            __("Fill multiple user at the same time and send each information individually. Each recipient will receive only it's own information.")
        }
      </Segment>
      <p className="bold" style={{ fontSize: '16px', fontWeight: 'bold' }}>{__('Choose your report')}</p>
      <ListView
        renderRow={this.renderRow}
        source={nodes.filter(report => report.fields.nodes.length)}
        pagination={{
          graphql: true,
          hasNextPage: pageInfo && pageInfo.hasNextPage,
          loading: data.loading
        }}
        onLoadMore={this.loadMore}
      />
    </div>);
  }

  checkBeforeLeaving = () => {
    // this.props.store.appends.push(<BetaModal
    //   id="ReportCloseModal"
    //   size="tiny"
    //   onClose={() => this.props.store.appends.pop()}
    //   header={__('Discard report', this.props.object)}
    //   content={this.props.edit ? __('Are you sure you want to discard changes made to this report?') : __('Are you sure you want to discard this report?')}
    //   actions={[
    //     <Button
    //       data-action="cancel"
    //       key={0}
    //       basic
    //       floated="left"
    //       content={__('Cancel')}
    //       onClick={() => this.props.store.appends.pop()}
    //     />,
    //     <Button
    //       data-action="submit"
    //       key={2}
    //       negative
    //       content={__('Discard')}
    //       onClick={() => {
    //         this.props.store.appends.pop();
    //         if (this.props.edit) {
    //           this.props.onCancel(this.state.recipients, this.state.selectedAll !== undefined ? this.state.selectedAll : this.props.selectedAll, this.state.fetchedAll || this.props.fetchedAll);
    //         } else {
    //           this.setState({ values: { ...this.defaultValues, multiple: this.state.values.multiple, report: this.state.values.report }, errors: {}, selectedField: 0 });
    //         }
    //       }}
    //     />
    //   ]}
    // />);

    this.props.store.appends.push(<BetaModal
      id="ReportCloseModal"
      size={'tiny'}
      onClose={() => this.props.store.appends.pop()}
      header={__('Discard report', this.props.object)}
      content={this.props.edit ? __('Are you sure you want to discard changes made to this report?') : __('Are you sure you want to discard this report?')}
      actions={
        [
          <BetaButton
            data-action="cancel"
            key={0}
            round transparent
            text={__('Cancel')}
            style={{ marginRight: 'auto', marginLeft: 0 }}
            onClick={() => this.props.store.appends.pop()}
          />,
          <BetaButton
            data-action="submit"
            key={2}
            round red
            text={__('Discard')}
            style={{ marginRight: 0 }}
            onClick={() => {
              this.props.store.appends.pop();
              if (this.props.edit) {
                this.props.onCancel(this.state.recipients, this.state.selectedAll !== undefined ? this.state.selectedAll : this.props.selectedAll, this.state.fetchedAll || this.props.fetchedAll);
              } else {
                this.setState({ values: { ...this.defaultValues, multiple: this.state.values.multiple, report: this.state.values.report }, errors: {}, selectedField: 0 });
              }
            }}
          />]}
    />);
  }

  render() {
    const { values } = this.state;
    const formProps = { submitButton: this.props.submitButton, onSubmit: this.onSubmit(), onCancel: this.onCancel };
    let title;
    let view;

    if (values.fields && values.fields.length) {
      title = values.report.name;
      view = !values.multiple ? this.renderFieldsView() : this.renderMultiple();
      formProps.cancelButton = { text: __('Back'), isActionButtom: true };

      if (values.multiple) {
        const empty = !values.fields.filter((f, i) => !!values.entities.filter(entity => this.checkAnswered(f.type, entity.fields[i])).length).length;

        if (values.entities.length === 0 || empty) {
          formProps.submitButton = { ...formProps.submitButton, disabled: true, isActionButtom: true };
        }
      } else {
        const empty = !values.fields.filter(f => this.checkAnswered(f.type, f.result)).length;

        formProps.submitButton = { ...formProps.submitButton, disabled: empty, isActionButtom: true };
      }

      formProps.cancelButton = { text: this.props.edit ? __('Cancel') : __('Back'), isActionButtom: true };
    } else {
      title = __('Add Report');
      view = this.renderOptions();

      if (!values.report) formProps.submitButton = { ...formProps.submitButton, disabled: true, isActionButtom: true };

      formProps.cancelButton = { text: __('Cancel'), isActionButtom: true };
      formProps.submitButton = { ...formProps.submitButton, text: __('Next'), isActionButtom: true };
    }
    formProps.submitButton = { ...formProps.submitButton, isActionButtom: true };

    return (
      <BetaModal
        id={'reporResultModal'}
        onClose={formProps.onCancel}
        size={!(values.fields && values.fields.length) ? 'small' : 'large'}
        actions={[<BetaButton
          data-action="cancel"
          round
          transparent
          floated="left"
          text={formProps.cancelButton.text}
          onClick={formProps.onCancel}
        />, <BetaButton
          data-action="submit"
          round
          icon={{ name: 'check' }}
          disabled={formProps.submitButton.disabled}
          text={formProps.submitButton.text}
          onClick={formProps.onSubmit}
        />
        ]}
        scrolling
        header={title}
        content={
          <div style={values.multiple && values.fields && values.fields.length ? styles.content : {}}>
            <Form id="ReportResultForm" {...this.props} {...formProps}>
              {view}
            </Form>
          </div>
        }
      />
    );
  }
}

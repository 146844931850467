import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Segment, Button, Dropdown, Divider } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Modal from '../../components/Modal';
import Page from '../../components/Page';
import Avatar from '../../components/Avatar';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  iconStyle: {
    fontSize: '18px',
    padding: '8px'
  }
};


@inject('store')
@graphql(gql`query ReportResultMultipleQuery ($messageId: ID!, $reportId: ID!, $entityId: [ID], $entitiesCount: Int) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        report (id: $reportId) {
          id: dbId
          name
          entities (limit: $entitiesCount) {
            nodes {
              id: dbId
              fullname
              picture {
                uri
                id: dbId
                key
              }
            }
          }
          results (entityId: $entityId, orderBy: { column: ID }, limit: 200) {
            nodes {
              name
              type
              value
              reportFieldId
            }
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
    messageId: ownProps.params.message_id,
    reportId: ownProps.report.id,
    entityId: [ownProps.report.entity.id],
    entitiesCount: ownProps.report.entities.totalCount
    }
    })
  })
@observer
export default class ReportResultMultiple extends Page {
  constructor(props) {
    super(props);

    this.state = {
      selectedEntity: 0,
      query: {
        messageId: props.params.message_id,
        reportId: props.report.id,
        entityId: [props.report.entity.id]
      }
    };
  }

  eventReload = (to) => {
    const { query } = this.state;
    let { selectedEntity } = this.state;
    const { node, loading } = this.props.data;

    if (loading) return;

    const { entities } = node.report;

    if (to === 'left') {
      query.entityId = [entities.nodes[selectedEntity - 1].id];
      selectedEntity -= 1;
    } else {
      query.entityId = [entities.nodes[selectedEntity + 1].id];
      selectedEntity += 1;
    }

    this.setState({ query, selectedEntity });

    this.props.data.refetch({
      ...query
    });
  }

  onEntityChange = (selectedEntity) => {
    const { query } = this.state;
    const { node, loading } = this.props.data;

    if (loading) return;

    const { entities } = node.report;

    query.entityId = entities.nodes[selectedEntity].id;

    this.setState({ query, selectedEntity });

    this.props.data.refetch({
      ...query
    });
  }

  renderReportFields = results => results.nodes.map((result, i) => [
    (i !== 0) && result.reportFieldId <= results.nodes[i - 1].reportFieldId ?
      <Table.Row
        key={`${i}_divider`}
      >
        <Table.Cell style={{ border: 'none', padding: 0 }}>
          <Divider />
        </Table.Cell>
        <Table.Cell style={{ border: 'none', padding: 0 }}>
          <Divider />
        </Table.Cell>
      </Table.Row>
      : null,
    <Table.Row
      className="reportResultItem"
      title={result.name}
      data-id={result.reportFieldId}
      key={i}
      style={{ backgroundColor: i % 2 === 0 ? null : '#f6f7f8' }}
    >
      <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ width: '50%', border: 'none', fontWeight: 'bold' }}>{result.name}</Table.Cell>
      <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none' }}>{result.value.join(', ')}</Table.Cell>
    </Table.Row>
  ])

  entityOptions = () => this.props.data.node.report.entities.nodes.map((entity, i) => ({
    value: i,
    text: entity.fullname,
    content: <div>
      <Avatar avatar spaced="right" style={{ height: '24px', width: '24px' }} src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} />
      {entity.fullname}
    </div>,
    entity
  }))

  searchFilter = (options, target) => {
    const newTarget = utils.normalize(target).toLowerCase();
    const newOptions = options.filter(option => utils.normalize(option.text).toLowerCase().indexOf(newTarget) !== -1);

    return newOptions;
  }

  render() {
    const { data, store } = this.props;
    const { selectedEntity } = this.state;

    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { name, results, entities } = data.node.report;
    const entity = entities.nodes[selectedEntity];
    const left = selectedEntity > 0 ? { onClick: () => this.eventReload('left'), className: 'pointer buttonHover' } : { disabled: true };
    const right = selectedEntity < entities.nodes.length - 1 ? { onClick: () => this.eventReload('right'), className: 'pointer buttonHover' } : { disabled: true };

    return (
      <Modal id="MessageReportResult" open size="small" closeIcon="times close" onClose={() => store.appends.pop()}>
        <Modal.Header style={{ border: 'none' }}>{__('%s: All Reports', name)}</Modal.Header>
        <Modal.Content style={{ padding: '0px', backgroundColor: '#ffffff' }}>
          <Dropdown
            ref={(c) => { this.dropdown = c; }}
            fluid scrolling selection
            selectOnBlur={false} icon={'search'} minCharacters={0} noResultsMessage={__('No results were found')}
            value={selectedEntity}
            options={this.entityOptions()}
            onChange={(e, { value }) => this.onEntityChange(value)}
            search={this.searchFilter}
            style={{ border: 'none', borderBottom: '1px #eeeeee solid', color: '#c8c8c8' }}
          />
          <Segment basic style={{ margin: '0px' }}>
            <div className="horizontallySpacedItems" style={{ paddingBottom: '14px', alignItems: 'center', borderBottom: '1px solid #eeeeee' }}>
              <Button circular basic icon="angle left" {...left} style={{ ...styles.iconStyle }} />
              <div className="bold">
                <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} />
                {entity.fullname}
              </div>
              <Button circular basic icon="angle right" {...right} style={{ ...styles.iconStyle }} />
            </div>
            <Table basic="very">
              <Table.Body>
                { this.renderReportFields(results) }
              </Table.Body>
            </Table>
          </Segment>
        </Modal.Content>
      </Modal>
    );
  }
}

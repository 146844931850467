import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Card, Container, Icon, Divider } from 'semantic-ui-react';
import gql from 'graphql-tag';

import ReportResultMultiple from './Multiple';

import Responsive from '../../components/Responsive';

import { __ } from '../../i18n';

@inject('store')@observer
export default class ReportResultItem extends Responsive {
  static fragments = {
    // if you need the id add to the component using the fragment
    // querying here can cause conflicts cause we need dbId in some components and key in others
    report: gql`
      fragment ReportResultItem on Report {
        name
        entity {
          id: dbId
          fullname
        }
        entities {
          totalCount
        }
        results (orderBy: { column: ID }, limit: 200) {
          nodes {
            reportFieldId
            entityId
            name
            type
            value
          }
        }
      }`,
  }

  renderReportFields = results => results.nodes.map((result, i) => [
    (i !== 0) && result.reportFieldId <= results.nodes[i - 1].reportFieldId ?
      <Table.Row
        key={`${i}_divider`}
      >
        <Table.Cell style={{ border: 'none', padding: 0 }}>
          <Divider />
        </Table.Cell>
        <Table.Cell style={{ border: 'none', padding: 0 }}>
          <Divider />
        </Table.Cell>
      </Table.Row>
      : null,
    <Table.Row
      className="reportResultItem"
      title={result.name}
      data-id={result.reportFieldId}
      key={i}
      style={{ backgroundColor: i % 2 === 0 ? null : '#f6f7f8' }}
    >
      <Table.Cell textAlign={this.isMobile() ? 'left' : 'right'} style={{ width: '50%', border: 'none', fontWeight: 'bold' }}>{result.name}</Table.Cell>
      <Table.Cell textAlign="left" style={{ whiteSpace: 'pre-line', border: 'none' }}>{result.value.join(', ')}</Table.Cell>
    </Table.Row>
  ])

  render() {
    const { report, store, params, messageType } = this.props;
    const { entity, id, name, results, entities } = report;

    return (
      <Card
        className="reportItem"
        title={name}
        data-id={id}
        fluid
        style={{ marginBottom: '1em' }}
      >
        <Card.Content>
          <Container>
            <div className="horizontallySpacedItems">
              <div className="bold"><Icon name="list layout" style={{ color: '#0080ff', margin: '0 .25rem 0 0', padding: 0 }} />{name}</div>
              {/* <Icon name="ellipsis v" /> */}
            </div>
            <Table basic="very">
              <Table.Body>
                {
                  (messageType === 'SENT' || params.organization_id) && entity ?
                    <Table.Row key={'details'}>
                      <Table.Cell textAlign="left" style={{ borderBottom: '#eeeeee 1px solid' }}>
                        <div>{__('You are viewing')} <span className="bold" style={{ color: '#0080ff' }}> {__("%s's Report", entity.fullname)}</span></div>
                      </Table.Cell>
                      <Table.Cell textAlign="right" style={{ borderBottom: '#eeeeee 1px solid' }}>
                        <div
                          className="bold pointer"
                          style={{ color: '#0080ff', fontSize: '12px' }}
                          onClick={() => store.appends.push(<ReportResultMultiple report={report} params={params} />)}
                        >
                          {__('View all reports').toUpperCase()} {`(${entities.totalCount})`}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                    :
                    null
                }
                { this.renderReportFields(results) }
              </Table.Body>
            </Table>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
